<template>
	<div>
		<jy-dialog type="form" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth">
			<div>
				<el-form :model="diaform" ref="diaform" :rules="rules" :label-width="formLabelWidth">
					<el-form-item label="设备ID:" prop="clientId">
						<el-input v-model="diaform.clientId" :disabled="type != 'add'"></el-input>
					</el-form-item>
					<el-form-item label="设备类型:" prop="dType">
						<el-select v-model="diaform.dType" placeholder="请选择">
							<el-option v-for="item in sboptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="设备厂商:" prop="vendor">
						<el-input v-model="diaform.vendor"></el-input>
					</el-form-item>
					<el-form-item label="ICCID:" prop="iccId">
						<el-input v-model="diaform.iccId"></el-input>
					</el-form-item>
					<el-form-item label="设备序列号:" prop="seriesNu">
						<el-input v-model="diaform.seriesNu"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
				<el-button type="primary" @click="save">确 定</el-button>
			</div>
		</jy-dialog>
	</div>
</template>
<script>
import { deviceType } from '@/common/js/wordbook.js'
export default {
	data() {
		let deviceTypeList = deviceType()
		return {
			// 对话框类型
			type: "",
			title: "",

			diaform: {
				clientId: "",
				dType: 0,
				vendor: "",
				iccId: "",
				seriesNu: "",
			},
			dialogVisible: false,
			formLabelWidth: "120px",
			sboptions: [
				...deviceTypeList
			],

			rules: {
				clientId: [{
					required: true,
					message: "请输入设备ID",
					trigger: "blur",
				}, ],
				dType: [{
					required: true,
					message: "请选择类型",
					trigger: "change",
				}, ],
			},
		};
	},
	components: {},
	activated() {},
	watch: {
		dialogVisible(newview) {
			if (!newview) {
				this.resetForm("diaform");
			}
		},
	},
	methods: {
		// 打开对话框
		init(option, type) {
			this.dialogVisible = true;
			this.type = type || "add";

			if (this.type == "update") {
				this.title = "编辑";
				this.$nextTick(() => {
					this.diaform = JSON.parse(JSON.stringify(option));
				});
			} else {
				this.title = "新增";
			}
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},

		cancel() {
			this.dialogVisible = false;
			console.log("cancel");
		},
		save() {
			this.$refs.diaform.validate((valid) => {
				if (valid) {
					let url = "";
					if (this.type == "add") {
						url = "/base/device/add";
					} else {
						url = "/base/device/update";
					}
					let option = this.diaform;
					this.$http.post(url, option).then((res) => {
						this.$message({
							message: res.msg,
							type: "success",
						});
						this.dialogVisible = false;
						this.$emit("updateList");
					});
				} else {
					return false;
				}
			});
		},
	},
};

</script>
<style lang="scss" scoped>
</style>

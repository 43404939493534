<template>
    <div class="lineManagement">
        <jy-query ref="checkForm" :model="formInline">
            <jy-query-item label="设备ID:" prop="clientId">
                <el-input v-model="formInline.clientId" placeholder="请输入"></el-input>
            </jy-query-item>
            <jy-query-item label="设备类型:" prop="dType">
                <el-select v-model="formInline.dType" placeholder="请选择">
                    <el-option v-for="item in typeoptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="所属车辆:" prop="plateNum">
                <el-input v-model="formInline.plateNum" placeholder="请输入"></el-input>
            </jy-query-item>
            <jy-query-item label="车辆绑定状态:" prop="hasBind" label-width="110px">
                <el-select v-model="formInline.hasBind" placeholder="请选择">
                    <el-option v-for="item in zzoptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" v-if="btnexist('scheduleBasicEquipMentList')">查询</el-button>
                <el-button type="primary" @click="resetForm('checkForm')" v-if="btnexist('scheduleBasicEquipMentReset')">重置</el-button>
                <!-- <el-button type="primary" @click="onexport">导出</el-button> -->
                <el-row>
                    <el-button type="primary" @click="add" v-if="btnexist('scheduleBasicEquipMentInsert')">新增</el-button>
                    <el-button type="primary" @click="batchdelete" v-if="btnexist('scheduleBasicEquipMentBatchDelete')">批量删除</el-button>
                    <!-- <el-button type="primary" @click="exportList" v-if="btnexist('export')">导出</el-button> -->
                    <!-- <el-button type="primary" @click="batchimport">导入</el-button> -->
                </el-row>
            </template>
        </jy-query>
        <jy-table ref="jyTable" :data="lists" @select-all="selectallchange" @select="selectchange">
            <jy-table-column type="index" width="60" label="序号" fixed="left"></jy-table-column>
            <jy-table-column type="selection" width="40" fixed="left"></jy-table-column>
            <jy-table-column prop="clientId" label="设备ID" min-width="120" fixed="left"></jy-table-column>
            <jy-table-column label="设备类型" min-width="100" fixed="left">
                <template slot-scope="scope">{{scope.row.dType|type(typeoptions)}}</template>
            </jy-table-column>
            <jy-table-column label="所属车辆" min-width="100">
                <template slot-scope="scope">{{scope.row.vehicleInfo?scope.row.vehicleInfo.plateNum:'-'}}</template>
            </jy-table-column>
            <jy-table-column prop="vendor" label="设备厂商" min-width="160"></jy-table-column>
            <jy-table-column prop="iccId" label="ICCID" min-width="160"></jy-table-column>
            <jy-table-column prop="seriesNu" label="设备序列号" min-width="160"></jy-table-column>
            <jy-operate v-bind:list="operateList" width="280"></jy-operate>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
        <add-dialog ref="dialog" @updateList="getlist"></add-dialog>
        <add-dialogcar ref="dialogcar" @updateList="getlist"></add-dialogcar>
        <!-- <add-dialogsim ref="dialogsim" @updateList="getlist"></add-dialogsim> -->
    </div>
</template>
<script>
import adddialog from '@/components/pages/admin/basicInformation/equipmentManagement/addequipment'
import adddialogcar from '@/components/pages/admin/basicInformation/equipmentManagement/addcar'
// import adddialogsim from '@/components/pages/admin/basicInformation/equipmentManagement/addsim'
import {btnMixins} from '@/common/js/button.mixin'
import {deviceType} from '@/common/js/wordbook.js'
export default {
    data() {
        let deviceTypeList = deviceType()
        return {
            formInline: {
                clientId: '',
                dType: '',
                plateNum: '',
                hasBind: '',
            },
            lists: [],
            typeoptions: [
                {
                    value: '',
                    label: '全部',
                },
                ...deviceTypeList
            ],
            zzoptions: [
                {
                    value: '',
                    label: '全部',
                },
                {
                    value: '1',
                    label: '已绑定',
                },
                {
                    value: '0',
                    label: '未绑定',
                },
            ],
            // 批量删除
            batchdeleteList: [],
            // 分页
            pageIndex: 1,
            total: 100,
            pageSize: 10,
            //  操作
            operateList: [],

            btnMenuId:'scheduleBasicEquipMent',
        }
    },
    mixins:[btnMixins],
    components: {
        'add-dialog': adddialog,
        'add-dialogcar': adddialogcar,
        // 'add-dialogsim': adddialogsim,
    },
    filters: {
        type(val, key) {
            let v = ''
            if (key.length) {
                key.forEach(item => {
                    if (item.value == val) {
                        v = item.label
                    }
                })
            }
            return v
        },
    },
    activated() {
        // this.getTypeoptions()
    },
    created() {
        this.getlist()
        this.setOperateList()
    },
    methods: {
        setOperateList() {
            let l = [
                {
                    name: '修改',
                    icon: 'el-icon-edit-outline',
                    fun: this.handleEdit,
                    isShow:()=>{
                        return this.btnexist('scheduleBasicEquipMentUpdate')
                    },
                },
                {
                    name: '删除',
                    icon: 'el-icon-delete',
                    fun: this.handleDelete,
                    isShow:()=>{
                        return this.btnexist('scheduleBasicEquipMentDelete')
                    },
                },
                {
                    name: '绑定车辆',
                    icon: 'el-icon-delete',
                    fun: this.handleCar,
                    isShow:row => {
                        if ((!row.vehicleId || row.vehicleId == ' ') && this.btnexist('scheduleBasicEquipMentBind')) {
                            return true
                        } else {
                            return false
                        }
                    },
                },
                {
                    name: '解绑车辆',
                    icon: 'el-icon-delete',
                    fun: this.handleunbundlingCar,
                    isShow:row => {
                        if (row.vehicleId && row.vehicleId != ' ' && this.btnexist('scheduleBasicEquipMentBind')) {
                            return true
                        } else {
                            return false
                        }
                    },
                },
                // {
                //     name: '绑定sim卡',
                //     icon: 'el-icon-delete',
                //     fun: this.sim,
                //     isShow:row => {
                //         if (!row.simNo && this.btnexist('bindSim')) {
                //             return true
                //         } else {
                //             return false
                //         }
                //     },
                // },
                // {
                //     name: '解绑sim卡',
                //     icon: 'el-icon-delete',
                //     fun: this.unbundlingsim,
                //     isShow:row => {
                //         if (row.simNo && this.btnexist('bindSim')) {
                //             return true
                //         } else {
                //             return false
                //         }
                //     },
                // },
            ]
            this.operateList = l
        },
        // getTypeoptions() {
        //     let url = '/BaseDeviceType/list'
        //     let option = {
        //         pageIndex: 0,
        //         pageSize: 0,
        //     }
        //     this.$http.post(url, option).then(res => {
        //         console.log(res)
        //         this.typeoptions = [{ name: '全部', id: '' }].concat(
        //             res.detail.list
        //         )
        //     })
        // },
        getlist() {
            let url = '/base/device/queryPage'
            let option = {
                ...this.formInline,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            this.$http.post(url, option).then(({ detail }) => {
                let list = detail.list.map(item=>{
                    return {
                        ...item,
                        ...item.sysOrg,
                        ...item.deviceInfo,
                    }
                })
                this.lists = list
                this.total = detail.total
            })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getlist()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.oncheck()
        },
        // 导出
        onexport() {
            console.log('导出')
        },
        // 新增
        add() {
            this.$refs.dialog.init()
            console.log('新增')
        },
        // 绑定sim
        // sim(row) {
            // console.log(row)
            // this.$refs.dialogsim.init(row)
        // },
        // 解绑sim
        unbundlingsim(row) {
            let url = '/baseinfodevice/bindOrUnbindSimNo'
            let option = {
                deviceId: row.deviceId,
                simNo: row.simNo,
                hasBind: '0',
            }
            this.$http.post(url, option).then(res => {
                this.$message({
                    message: res.msg,
                    type: 'success',
                })
                this.getlist()
            })
        },
        // 批量删除
        batchdelete() {
            if (!this.batchdeleteList.length) {
                this.$message({
                    type: 'warning',
                    message: '请先选择删除项',
                })
                return false
            }
            this.$confirm('是否删除选中设备?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let option = {
                        deviceIds:this.batchdeleteList.map(item=>item.deviceId).join(',')
                    }
                    this.$http.post('/base/device/batchDel', option).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        })
                        this.getlist()
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        // 批量导入
        batchimport() {},

        selectchange(selection) {
            console.log(selection)
            this.batchdeleteList = selection
        },
        selectallchange(selection) {
            console.log(selection)
            this.batchdeleteList = selection
        },

        // 删除
        handleDelete(row) {
            this.$confirm('是否删除该设备?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.$http.post('/base/device/del', {deviceId:row.deviceId}).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        })
                        this.getlist()
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
            //   console.log(row)
        },
        // 修改
        handleEdit(row) {
            this.$refs.dialog.init(row, 'update')
            console.log('编辑')
        },
        // 绑定车辆
        handleCar(row) {
            this.$refs.dialogcar.init(row)
        },
        // 解绑车辆
        handleunbundlingCar(row) {
            this.$confirm('是否确定解绑?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let url = '/base/device/unBind'
                    let option = {
                        deviceId: row.deviceId,
                    }
                    this.$http.post(url, option).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        })
                        this.getlist()
                    })
                })
                .catch(() => {
                })
        },
        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.pageSize = val
            this.getlist()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.pageIndex = val
            this.getlist()
        },

        //导出
        exportList() {
            let url = '/baseinfodevice/export'
            let option = {
                ...this.formInline,
            }
            this.$http
                .post(url, option, { type: 'format', isExcel: true })
                .then(res => {
                    this.$util.funDownload(res.data, '设备信息')
                })
        },
    },
}
</script>
<style lang="scss" scoped="brand">
</style>
<style lang="scss">
</style>
